import React, { Component } from "react";
// import { CSSTransitionGroup } from 'react-transition-group';
import { Link } from "react-router-dom";
import ThickBlueLine from "../components/ThickBlueLine";
import RandomStory from "../components/RandomStory";
import Logo from "../components/Logo";
import FeaturedMedia from "../components/FeaturedMedia";
import RemembranceExploreOtdContainer from "./RemembranceExploreOtdContainer";
import RemembranceSearch from "../components/RemembranceSearch";
import media from "../media.json";
import Parser from "rss-parser/dist/rss-parser";

const parser = new Parser();

class DashboardContainer extends Component {
  state = {
    feed: [],
    feedImage: null,
    isFetchingTweets: true,
    tweetsFailedToFetch: false,
  };

  // On mounting load the UKPM feed
  componentDidMount() {
    let next = this;
    parser.parseURL(
      "https://api.ukpolicememorial.org/twitter",
      function (err, feed) {
        if (feed) {
          next.setState({
            feed: feed,
            feedImage: feed.image.url,
            isFetchingTweets: false,
          });
        } else {
          next.setState({ tweetsFailedToFetch: true, isFetchingTweets: false });
        }
      }
    );
  }

  render() {
    // Set some hardcoded data for the featured media
    const data = media;

    // Select a random profile from the above array
    let randomProfile = data[Math.floor(Math.random() * data.length)];

    let tweets = [];
    if (this.state.feed.items) {
      tweets = this.state.feed.items.map((item) => {
        // Check the tweet is not a RT
        if (item.creator === " (@ukpmemorial)") {
          return (
            <li className="tweets__item">
              <a
                href={item.link}
                rel="noopener noreferrer"
                target="_blank"
                className="tweets__link"
              >
                <div className="tweets__content">{item.contentSnippet}</div>
              </a>
            </li>
          );
        }
        return null;
      });
    }
    return (
      <div className="dashboard-container">
        <Logo />
        <ThickBlueLine />
        <div className="dashboard__mask"></div>
        <div className="dashboard__fade"></div>

        <div className="dashboard">
          <section className="dashboard__first">
            {/* Introduction */}
            <article className="dashboard__introduction">
              <div className="dashboard-box__content">
                <h2 className="dashboard-box__title">
                  Welcome to the Digital UK Police Memorial
                </h2>
                <p className="dashboard-box__body">
                  More than 4,000 men and women have given their lives in the
                  service of their communities while wearing the uniform of a
                  watchman, beadle, parish constable or police officer. The
                  formation of the Bow Street Runners in 1749 was the start of
                  modern policing but the earliest record of death on duty dates
                  back to 1547. This Digital Memorial remembers and memorialises
                  all these officers.{" "}
                  <a
                    rel="noopener noreferrer"
                    href="https://www.ukpolicememorial.com"
                    style={{ color: "#ffffff" }}
                    target="_blank"
                    title="Visit the police memorial website"
                  >
                    Please click here
                  </a>{" "}
                  to learn more about our vision.
                </p>
              </div>
            </article>

            {/* History article */}
            <RandomStory />

            {/* Roll of remembrance */}
            <article className="dashboard__remembrance dashboard-box">
              <header>
                <img
                  src="../../images/ui/supported_by_prht.png"
                  className="dashboard__remembrance-logo"
                  alt="The Police Roll of Honour Trust"
                />
                {/* <h2 className="dashboard-box__title" style={{marginBottom:0}}>Remembering the fallen on this day&hellip;</h2> */}
                {/* <p className="dashboard-box__body">Remembering the fallen on this day</p> */}
              </header>
              <div className="dashboard__officers">
                <RemembranceExploreOtdContainer fromDashboard={true} />
              </div>
              <div className="dashboard-box__actions">
                <ul>
                  <li className="dashboard-box__button">
                    <Link
                      className="button button--solid-dark-blue"
                      to="/remembrance"
                      title="Visit our Remembrance section"
                    >
                      Remember the fallen
                    </Link>
                  </li>
                </ul>
              </div>
            </article>
          </section>

          <section className="dashboard__second">
            {/* Twitter feed */}
            <article className="dashboard__twitter">
              {this.state.isFetchingTweets && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="loading"></div>
                </div>
              )}

              {!this.state.isFetchingTweets && !this.state.tweetsFailedToFetch && (
                <div>
                  <header>
                    <img
                      src={this.state.feedImage}
                      className="tweets-logo"
                      alt="UK Police Memorial Twitter logo"
                    />
                    <div>
                      <h2>UK Police Memorial</h2>
                      <h3>
                        <a
                          href="https://twitter.com/ukpmemorial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Follow us on Twitter
                        </a>
                      </h3>
                    </div>
                  </header>
                  <ul className="tweets">{tweets}</ul>
                </div>
              )}

              {this.state.tweetsFailedToFetch && (
                <div className="tweets--failed">
                  <img
                    src="../../images/ui/supported_by_twitter.png"
                    style={{ height: 40, marginBottom: 20 }}
                    alt="Twitter logo"
                  />
                  <h2>Follow the UK Police Memorial on Twitter</h2>
                  <p>
                    Find our{" "}
                    <a
                      href="https://twitter.com/ukpmemorial"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter page here
                    </a>
                  </p>
                </div>
              )}
            </article>

            {/* Featured media item */}
            <FeaturedMedia randomProfile={randomProfile} />
          </section>

          <section className="dashboard__third">
            {/* Supported by */}
            <article className="dashboard__explore dashboard-box">
              <div className="dashboard-box__content">
                <h2 className="dashboard-box__title dashboard__explore-title">
                  Explore the UK Police Memorial
                </h2>

                <ul
                  className="explore-start__list"
                  style={{ textAlign: "center" }}
                >
                  <li className="explore-start__item">
                    <Link
                      to="/remembrance/explore/otd"
                      className="explore-start__link"
                      title="Explore fallen officers on this day in history"
                    >
                      <div className="explore-start__image">
                        <img
                          src="/images/ui/icon-calendar.svg"
                          alt="Explore fallen officers on this day in history"
                        />
                      </div>
                      <h2 className="explore-start__subtitle">
                        Fallen officers
                        <em>on this day</em>
                      </h2>
                    </Link>
                  </li>
                  <li className="explore-start__item">
                    <Link
                      to="/remembrance/explore/force"
                      className="explore-start__link"
                      title="Explore fallen officers by force"
                    >
                      <div className="explore-start__image">
                        <img
                          src="/images/ui/icon-country.svg"
                          alt="Explore fallen officers by force"
                        />
                      </div>
                      <h2 className="explore-start__subtitle">
                        Fallen officers
                        <em>by force</em>
                      </h2>
                    </Link>
                  </li>
                  <li className="explore-start__item">
                    <Link
                      to="/remembrance/explore/rank"
                      className="explore-start__link"
                      title="Explore fallen officers by rank"
                    >
                      <div className="explore-start__image">
                        <img
                          src="/images/ui/icon-ranks.svg"
                          alt="Explore fallen officers by rank"
                        />
                      </div>
                      <h2 className="explore-start__subtitle">
                        Fallen officers
                        <em>by rank</em>
                      </h2>
                    </Link>
                  </li>
                </ul>

                <h2 className="dashboard-box__title dashboard__explore-title">
                  Search for a fallen officer
                </h2>

                <div style={{ position: "relative" }}>
                  <RemembranceSearch fromDashboard={true} />
                </div>
              </div>
            </article>

            {/* Support us */}
            <article className="dashboard__support-us dashboard-box">
              <div className="dashboard-box__content">
                <img
                  src="../../images/ui/support.jpg"
                  alt="Artists impression of the memorial at the arboretum"
                  className="dashboard-box__background-image"
                />
                <div className="dashboard__support-us-content">
                  <h2 className="dashboard-box__title">Support Us</h2>
                  <p
                    className="dashboard-box__body"
                    style={{ marginBottom: 30 }}
                  >
                    On this day, we ask you to reflect on the courage of our
                    policemen and women who will not be with their families any
                    longer. Your gift will honour the courage and sacrifice of
                    the men and women of our Police service who have laid down
                    their lives to protect us and our families from harm and
                    keep us safe.
                  </p>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.ukpolicememorial.com/donate/"
                    target="_blank"
                    className="button button--empty-white"
                    title="Support us by making a donation"
                  >
                    Support our vision
                  </a>
                </div>
              </div>
            </article>
          </section>

          <div className="dashboard__footer">
            <ul className="supported-by">
              <li>
                <img
                  src="../../images/ui/supported_by_beacon.png"
                  style={{ height: 60 }}
                  alt="Beacon logo"
                />
                <p>Hosting &amp; Technology Partner</p>
              </li>
              <li>
                <img
                  src="../../images/ui/supported_by_prht.png"
                  style={{ height: 60 }}
                  alt="Police Roll of Honour Trust logo"
                />
                <p>Memorial Partner</p>
              </li>
              <li>
                <img
                  src="../../images/ui/supported_by_twitter.png"
                  style={{ height: 60 }}
                  alt="Twitter logo"
                />
                <p>Social Media Partner</p>
              </li>
            </ul>

            <ul>
              <li>
                <a
                  href="https://www.ukpolicememorial.com/terms-and-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </a>
              </li>
              <li>
                <a
                  href="https://www.ukpolicememorial.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
            <p>
              Copyright &copy; 2019 Police Arboretum Memorial Trust. Registered
              charity number 1159831.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardContainer;
