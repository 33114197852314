import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function CSSTransitionGroup(props) {
  return (
    <TransitionGroup>
      <CSSTransition {...props} />
    </TransitionGroup>
  );
}

class ThickBlueLine extends Component {
  constructor() {
    super();
    this.state = {
      showThickBlueLine: false,
    };
  }
  componentDidMount() {
    var self = this;
    var didScroll = false;

    window.onscroll = checkScroll;

    function checkScroll() {
      didScroll = true;
    }

    setInterval(function () {
      if (didScroll) {
        didScroll = false;
        var top = window.pageYOffset || document.documentElement.scrollTop;
        if (top > 0) {
          self.setState({ showThickBlueLine: true });
        } else {
          self.setState({ showThickBlueLine: false });
        }
      }
    }, 100);
  }

  render() {
    return (
      <div>
        {this.state.showThickBlueLine && (
          <CSSTransitionGroup
            classNames="thick-blue-line"
            appear={true}
            enter={false}
            exit={false}
            timeout={{ appear: 500 }}
          >
            <div className="thick-blue-line"></div>
          </CSSTransitionGroup>
        )}
      </div>
    );
  }
}

export default ThickBlueLine;
