import React, { Component } from 'react';
import RemembranceExploreByForceId from '../components/RemembranceExploreByForceId';

class RemembranceExploreByForceIdContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			officers: [],
			filterTerm: "", // default value to prevent error being thrown
			force: ""
		}
	}

	_handleFilter = (e) => {
		this.setState({
			filterTerm: e.target.value
		})
	}

	componentDidMount() {
		const self = this;

		window.client.post(
			'officers/force', 
			{
				"force_id": this.props.match.params.id, // get the ID from the url
			}
		).then(function(result){
			self.setState({
				force: result.forceName,
				loading: false,
	 			officers: result.officers
	 		})

		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get information for this police force. Please try again later.")
		});
	}

	render() {
		return (
			<RemembranceExploreByForceId 
			officers={this.state.officers} 
			force={this.state.force} 
			filter={this._handleFilter} 
			filterTerm={this.state.filterTerm} />
		)
	}
}

export default RemembranceExploreByForceIdContainer;