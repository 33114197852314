import React, { Component } from 'react';

class WallOfFaces extends Component {
	render() {
		const faces = this.props.faces.map(function(face, item){
			return (
				<li 
					key={item} 
					className={"wall-of-faces__item " + item} 
					style={{
						backgroundImage:'url(' + face.profilePicture + ')',
						"--animation-delay": `${0.015 * item}s`,
					}}
				>
					<img src={face.profilePicture} alt={face.name} style={{display:"none"}} />
				</li>
			)
		})

		return (
			<div className="wall-of-faces">
				<ul className="wall-of-faces__list" id="wall">
					{faces}
				</ul>
			</div>
		)
	}
}

export default WallOfFaces;
