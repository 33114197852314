import React, { Component } from "react";
import HomeButton from "../components/HomeButton";
import BackButton from "../components/BackButton";
import Logo from "../components/Logo";
import DashboardButton from "../components/DashboardButton";
import ThickBlueLine from "../components/ThickBlueLine";
import Slider from "../components/Slider";
import DocumentTitle from "react-document-title";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function CSSTransitionGroup(props) {
  return (
    <TransitionGroup>
      <CSSTransition {...props} />
    </TransitionGroup>
  );
}

class OfficerProfileFull extends Component {
  renderContent(blocks) {
    return blocks.map((block, item) => {
      const blockData = JSON.parse(block.data); // parse the data object to get correct formatting and children elements
      let content = null;

      switch (block.type) {
        case "page_break":
          return (content = <hr key={item} />);
        case "image_large":
        case "image_small":
          return (content = (
            <figure key={item} className="image">
              <img
                src={blockData.path}
                alt={blockData.caption}
                className="image__picture"
              />
              <figcaption className="image__details">
                <p className="image__caption">{blockData.caption}</p>
                <p className="image__source">
                  Source: <a href={blockData.source_link}>{blockData.source}</a>
                </p>
              </figcaption>
            </figure>
          ));
        case "one_col_text":
          return (content = (
            <div key={item} className="row">
              <div className="col">
                <p className={blockData.dropcap ? "dropcap" : ""}>
                  {blockData.value}
                </p>
              </div>
            </div>
          ));
        case "two_col_text":
          return (content = (
            <div key={item} className="row">
              <div className="col col--split">
                <p className={blockData.dropcap ? "dropcap" : ""}>
                  {blockData.column_1}
                </p>
              </div>
              <div className="col col--split col--split-last">
                <p>{blockData.column_2}</p>
              </div>
            </div>
          ));
        case "short_quote":
          return (content = (
            <blockquote key={item}>
              <p>{blockData.value}</p>
              <footer>{blockData.source}</footer>
            </blockquote>
          ));
        case "long_quote":
          return (content = (
            <blockquote key={item} className="blockquote--long">
              <p>{blockData.value}</p>
              <footer>{blockData.source}</footer>
            </blockquote>
          ));
        case "media_video":
          function youtube_parser(url) {
            var regExp =
              /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            var match = url.match(regExp);
            return match && match[7].length === 11 ? match[7] : false;
          }
          const video = blockData.value;
          const videoId = youtube_parser(video);

          return (content = (
            <div key={item} className="video">
              <iframe
                width="100%"
                height="506"
                className="video__player"
                title="YouTube Video"
                src={"https://www.youtube.com/embed/" + videoId + "?rel=0"}
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <p className="video__caption">{blockData.caption}</p>
              <p className="video__source">
                Source:{" "}
                <a href={blockData.source_link} target="_blank">
                  {blockData.source}
                </a>
              </p>
            </div>
          ));
        case "gallery":
          let i = 0;
          let data = [];
          for (i = 0; i < blockData.path.length; i++) {
            data.push({
              path: blockData.path[i],
              caption: blockData.caption[i],
              source: blockData.source[i],
            });
          }

          const slides = data;
          const config = {
            slideWidth: 540, // pixel width of slide
            slideMargin: 0, // pixel margin width applied to both sides
          };

          return (content = (
            <Slider key={item} slides={slides} config={config} />
          ));
        case "audio":
          return (content = (
            <div key={item} className="audio">
              <audio controls className="audio__player">
                <source src={blockData.audio.mp3} type="audio/mpeg" />
                <source src={blockData.audio.ogg} type="audio/ogg" />
              </audio>
              <p className="audio__caption">{blockData.caption}</p>
              <p className="audio__source">
                Source:{" "}
                <a href={blockData.source_link} target="_blank">
                  {blockData.source}
                </a>
              </p>
            </div>
          ));
        case "media_vimeo":
          // Get the Vimeo URL
          var url = blockData.value;
          // Create a regex on the Vimeo url
          var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
          // Check if it is a Vimeo URL
          var match = url.match(regExp);
          // Set a blank var for the video player
          let player = null;
          if (match[2]) {
            player = (
              <iframe
                title="Vimeo video"
                src={"https://player.vimeo.com/video/" + match[2]}
                width="100%"
                height="506"
                className="video__player"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            );
          }

          return (content = (
            <div key={item} className="video">
              {player}
              <p className="video__caption">{blockData.caption}</p>
              <p className="video__source">
                Source:{" "}
                <a href={blockData.source_link} target="_blank">
                  {blockData.source}
                </a>
              </p>
            </div>
          ));
        default:
          return null;
      }
    });
  }

  render() {
    return (
      <div>
        <BackButton />
        <HomeButton />
        <Logo />
        <DashboardButton />
        <DocumentTitle title={"Remembering " + this.props.officer.name} />

        <ThickBlueLine />

        <div className="officer-profile">
          <div className="officer-profile__header">
            <CSSTransitionGroup
              classNames="officer-profile__header-content"
              appear={true}
              enter={false}
              exit={false}
              timeout={{ appear: 3500 }}
            >
              <div className="officer-profile__header-content">
                {this.props.officer.profilePicture != null ? (
                  <img
                    src={this.props.officer.profilePicture}
                    className="officer-profile__picture"
                    alt={this.props.officer.name}
                  />
                ) : (
                  <img
                    src="/images/ui/no-officer-picture.svg"
                    className="officer-profile__picture"
                    alt={this.props.officer.name}
                  />
                )}
                <h1 className="officer-profile__name">
                  {this.props.officer.name}
                </h1>
                <div className="officer-profile__details">
                  {this.props.officer.rank.name},{" "}
                  {this.props.officer.force.name}
                </div>
                <div className="officer-profile__dates">
                  Died {this.props.officer.dateOfDeath.day}/
                  {this.props.officer.dateOfDeath.month}/
                  {this.props.officer.dateOfDeath.year}
                </div>
                <div className="officer-profile__bio">
                  {this.props.officer.shortBio}
                </div>
              </div>
            </CSSTransitionGroup>

            <CSSTransitionGroup
              classNames="officer-profile__hint"
              appear={true}
              enter={false}
              exit={false}
              timeout={{ appear: 4500 }}
            >
              <img
                className="officer-profile__hint"
                src="/images/ui/hint.svg"
                alt="Scroll down to read this profile"
              />
            </CSSTransitionGroup>
          </div>

          <div className="officer-profile__body">
            <div className="officer-profile__body-content">
              {this.props.officer.blocks &&
                this.renderContent(this.props.officer.blocks)}
            </div>
          </div>

          <div className="officer-profile__footer">
            <div className="officer-profile__footer-content">
              <h3 className="share-this-page">Share page</h3>
              <ul>
                <li className="share-icons__item">
                  <a
                    href={
                      "https://twitter.com/intent/tweet?text=Remembering " +
                      this.props.officer.name +
                      " who died on " +
                      this.props.officer.dateOfDeath.day +
                      "/" +
                      this.props.officer.dateOfDeath.month +
                      "/" +
                      this.props.officer.dateOfDeath.year +
                      ". Read their story here: https://digital.ukpolicememorial.org/remembrance/profile/" +
                      this.props.officer.id
                    }
                    className="share-icons__link"
                    title="Share on Twitter"
                    target="_blank"
                  >
                    <img
                      src="../../images/ui/button-twitter-blue.svg"
                      className="share-icons__icon"
                      alt="Share on Twitter"
                    />
                  </a>
                </li>
                <li className="share-icons__item">
                  <a
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=https://digital.ukpolicememorial.org/remembrance/profile/" +
                      this.props.officer.id
                    }
                    className="share-icons__link"
                    title="Share on Twitter"
                    target="_blank"
                  >
                    <img
                      src="../../images/ui/button-facebook-blue.svg"
                      className="share-icons__icon"
                      alt="Share on Facebook"
                    />
                  </a>
                </li>
                <li className="share-icons__item">
                  <a
                    href={
                      "mailto:?subject=Remembering " +
                      this.props.officer.name +
                      "&body=Visit the online memorial for " +
                      this.props.officer.name +
                      " at https://digital.ukpolicememorial.org/remembrance/profile/" +
                      this.props.officer.id
                    }
                    className="share-icons__link"
                    title="Share by email"
                  >
                    <img
                      src="../../images/ui/button-email-blue.svg"
                      className="share-icons__icon"
                      alt="Share by email"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfficerProfileFull;
