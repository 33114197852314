import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

class RemembranceExplore extends Component {
	render() {
		return (
			<div className="explore-start">
				<DocumentTitle title="Explore the UK Police Memorial" />

				<h1 className="explore-start__title">Explore the UK Police Memorial</h1>
				<ul className="explore-start__list">
					<li className="explore-start__item">
						<Link to="/remembrance/explore/otd" className="explore-start__link" title="Explore fallen officers on this day in history">
							<div className="explore-start__image">
								<img src="/images/ui/icon-calendar.svg" alt="Explore fallen officers on this day in history" />
							</div>
							<h2 className="explore-start__subtitle">
								Fallen officers
								<em>on this day</em>
							</h2>
						</Link>
					</li>
					<li className="explore-start__item">
						<Link to="/remembrance/explore/force" className="explore-start__link" title="Explore fallen officers by force">
							<div className="explore-start__image">
								<img src="/images/ui/icon-country.svg" alt="Explore fallen officers by force" />
							</div>
							<h2 className="explore-start__subtitle">
								Fallen officers
								<em>by force</em>
							</h2>
						</Link>
					</li>
					<li className="explore-start__item">
						<Link to="/remembrance/explore/rank" className="explore-start__link" title="Explore fallen officers by rank">
							<div className="explore-start__image">
								<img src="/images/ui/icon-ranks.svg" alt="Explore fallen officers by rank" />
							</div>
							<h2 className="explore-start__subtitle">
								Fallen officers
								<em>by rank</em>
							</h2>
						</Link>
					</li>
				</ul>
			</div>
		)
	}
}

export default RemembranceExplore