import React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

const RemembranceStart = () => {
	return(
		<div className="remembrance-start">
			<DocumentTitle title="UK Police Memorial" />

			<div className="remembrance-start__main">
				<img src={"../../images/ui/prht_logo.png"} className="remembrance-start__logo" alt="The Police Roll of Honour Trust" />
				<div className="remembrance-start__prht">The Police Roll of Honour Trust</div>
				<h1 className="remembrance-start__title">Remembrance</h1>
				<p className="remembrance-start__body">
					Welcome to this digital experience designed to honour those who have lost their lives serving and protecting communities across the UK.
				</p>
			</div>
			<div className="remembrance-start__actions">
				<ul>
					<li className="remembrance-start__button">
						<Link className="button button--solid-dark-blue" to="/remembrance/search" title="Search for fallen officers">Search</Link>
					</li>
					<li className="remembrance-start__button">
						<Link className="button button--solid-dark-blue" to="/remembrance/explore" title="Explore the digital memorial">Explore</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default RemembranceStart