import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Logo from '../components/Logo';
import DashboardButton from '../components/DashboardButton';
import Slider from '../components/Slider';
import { Link } from 'react-router-dom';
import ThickBlueLine from '../components/ThickBlueLine';

class HistoryEvent extends Component {
	renderContent(blocks){

		return blocks.map((block, item) => {
			const blockData = JSON.parse(block.data); // parse the data object to get correct formatting and children elements
			let content = null;

			switch(block.type){
				case "page_break":
					return content = <hr key={item} />
				case "image_large":
				case "image_small":
					return content = (
						<figure key={item} className="image">
							<img src={blockData.path} alt={blockData.caption} className="image__picture" />
							<figcaption className="image__details">
								<p className="image__caption">{blockData.caption}</p>
								<p className="image__source">Source: <a href={blockData.source_link}>{blockData.source}</a></p>
							</figcaption>
						</figure>
					)
				case "one_col_text":
					return content = (
						<div key={item} className="row">
							<div className="col">
								<p className={(blockData.dropcap) ? "dropcap" : ""}>{blockData.value}</p>
							</div>
						</div>
					)
				case "two_col_text":
					return content = (
						<div key={item} className="row">
							<div className="col col--split">
								<p className={(blockData.dropcap) ? "dropcap" : ""}>{blockData.column_1}</p>
							</div>
							<div className="col col--split col--split-last">
								<p>{blockData.column_2}</p>
							</div>
						</div>
					)
				case "short_quote":
					return content = (
						<blockquote key={item}>
							<p>{blockData.value}</p>
							<footer>{blockData.source}</footer>
						</blockquote>
					)
				case "long_quote":
					return content = (
						<blockquote key={item} className="blockquote--long">
							<p>{blockData.value}</p>							
							<footer>{blockData.source}</footer>
						</blockquote>
					)
				case "media_video":
					function youtube_parser(url){
					    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
					    var match = url.match(regExp);
					    return (match&&match[7].length===11)? match[7] : false;
					}
					const video = blockData.value
					const videoId = youtube_parser(video)

					return content = (
						<div key={item} className="video">
							<iframe width="100%" height="506" className="video__player" title="video"
								src={"https://www.youtube.com/embed/" + (videoId) + "?rel=0" }
								frameBorder="0" allowFullScreen>
							</iframe>
							<p className="video__caption">{blockData.caption}</p>
							<p className="video__source">Source: <a href={blockData.source_link} target="_blank">{blockData.source}</a></p>
						</div>
					)
				case "gallery":

					let i = 0;
					let data = [];
					for(i = 0; i < blockData.path.length; i++){
						data.push({ 
							path:blockData.path[i], 
							caption:blockData.caption[i], 
							source:blockData.source[i] 
						})
					}

					const slides = data
					const config = { 
						slideWidth: 540, // pixel width of slide
						slideMargin: 0, // pixel margin width applied to both sides
					}

					return content = (
						<Slider key={item} slides={slides} config={config} />
					)
				case "audio":
					return content = (
						<div key={item} className="audio">
							<audio controls className="audio__player">
								<source src={blockData.audio.mp3} type="audio/mpeg" />
								<source src={blockData.audio.ogg} type="audio/ogg" />
							</audio>
							<p className="audio__caption">{blockData.caption}</p>
							<p className="audio__source">Source: <a href={blockData.source_link} target="_blank">{blockData.source}</a></p>
						</div>
					)
				default: 
					return null
			}
		})
	}

	render() {
		return(
			<div>
				<Link to="/history" className="back-button back-button--events button button--empty-white" aria-label="Go back to the events">
					<span>Events</span>
				</Link>
				<Logo />
				<DashboardButton />
				<DocumentTitle title={this.props.event.name} />

				<ThickBlueLine />

				<div className="event">

					<div className="event-header">
						<div className="event-header__image" style={{backgroundImage:"url("+this.props.event.image+")"}}></div>
						<div style={{position:"relative",zIndex:1}}>
							<h1 className="event-header__title">{this.props.event.name}</h1>
							<div className="event-header__date">{this.props.event.day}/{this.props.event.month}/{this.props.event.year}</div>
						</div>
					</div>

					<div className="event-body">
						<div className="event-body__content">
							{this.props.event.blocks && this.renderContent(this.props.event.blocks)}
						</div>
					</div>

					<div className="event-footer">
						<div className="event-footer__content">
							<h3 className="share-this-page">Share page</h3>
							<ul>
								<li className="share-icons__item">
									<a href={"https://twitter.com/intent/tweet?text=" + this.props.event.name + " - Find out more at: https://digital.ukpolicememorial.org/history/" + this.props.event.id} 
									className="share-icons__link" title="Share on Twitter" target="_blank">
										<img src="../../images/ui/button-twitter-blue.svg" className="share-icons__icon" alt="Share on Twitter" />
									</a>
								</li>
								<li className="share-icons__item">
									<a href={"https://www.facebook.com/sharer/sharer.php?u=https://digital.ukpolicememorial.org/history/" + this.props.event.id} 
									className="share-icons__link" title="Share on Twitter" target="_blank">
										<img src="../../images/ui/button-facebook-blue.svg" className="share-icons__icon" alt="Share on Facebook" />
									</a>
								</li>
								<li className="share-icons__item">
									<a href={"mailto:?subject=" + this.props.event.name + "&body=Visit the online memorial to learn more about " + this.props.event.name + " at https://digital.ukpolicememorial.org/history/" + this.props.event.id} 
									className="share-icons__link" title="Share by email">
										<img src="../../images/ui/button-email-blue.svg" className="share-icons__icon" alt="Share on by email" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default HistoryEvent;