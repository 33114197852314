import React from 'react';

const PageNotFound = () => {
	return (
		<div className="not-found">
			<h1 className="not-found__title">Page not found (404)</h1>
			<p className="not-found__body">The page you are looking for has either been deleted or moved. If you feel this is an error, please <a rel="noopener noreferrer" href="http://www.ukpolicememorial.org/contact" target="_blank">contact us</a> to let us know.</p>
		</div>)
}


export default PageNotFound;