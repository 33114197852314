import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Route, Switch, Redirect } from "react-router-dom";

// Import containers
import DashboardContainer from "./containers/DashboardContainer";
import RemembranceSearchResultsContainer from "./containers/RemembranceSearchResultsContainer";
import OfficerProfileCardContainer from "./containers/OfficerProfileCardContainer";
import OfficerProfileFullContainer from "./containers/OfficerProfileFullContainer";
import RemembranceExploreOtdContainer from "./containers/RemembranceExploreOtdContainer";
import RemembranceExploreByForceContainer from "./containers/RemembranceExploreByForceContainer";
import RemembranceExploreByForceIdContainer from "./containers/RemembranceExploreByForceIdContainer";
import RemembranceExploreByRankContainer from "./containers/RemembranceExploreByRankContainer";
import RemembranceExploreByRankIdContainer from "./containers/RemembranceExploreByRankIdContainer";
import WallOfFacesContainer from "./containers/WallOfFacesContainer";
import HistoryStartContainer from "./containers/HistoryStartContainer";
import HistoryEventContainer from "./containers/HistoryEventContainer";

// Import components
import RemembranceStart from "./components/RemembranceStart";
import RemembranceExplore from "./components/RemembranceExplore";
import RemembranceSearch from "./components/RemembranceSearch";
import Logo from "./components/Logo";
import DashboardButton from "./components/DashboardButton";
import HomeButton from "./components/HomeButton";
import BackButton from "./components/BackButton";
import PageNotFound from "./components/PageNotFound";

function CSSTransitionGroup(props) {
  return (
    <TransitionGroup>
      <CSSTransition {...props} />
    </TransitionGroup>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faces: [],
      facesLoaded: 1,
      loading: true,
    };
  }

  // After mounting get the wall images into state for rendering (invisibly) below
  // this means the pictures will be pre-loaded when the user clicks into the remembrance app
  componentDidMount() {
    var self = this;

    window.client
      .post("wall/get")
      .then(function (result) {
        self.setState({
          faces: result.pictures,
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
		Each time this is called we are incrementing the number of faces loaded
		When it matches the number of faces that WILL load (the array length tells us this), set loading to false
	*/
  _handleOnLoad() {
    this.setState({
      facesLoaded: this.state.facesLoaded + 1,
    });

    if (this.state.facesLoaded === this.state.faces.length) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading === true) {
      return (
        <div>
          {/* 
						Actually render the faces (hidden) 
						For each load fire the _handleOnLoad function
					*/}
          <div style={{ display: "none" }} aria-hidden="true">
            {this.state.faces.map((face, item) => {
              return (
                <img
                  key={item}
                  src={face.profilePicture}
                  alt={face.name}
                  onLoad={this._handleOnLoad.bind(this)}
                />
              );
            })}
          </div>

          <img
            src="/images/ui/dashboard-background.jpg"
            style={{ display: "none" }}
            aria-hidden="true"
            alt="Welcome to the UK Police Digital Memorial"
          />

          <div className="loading--welcome">
            <span className="loading--welcome__text">
              Loading the digital memorial, please wait...
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <DocumentTitle title="UK Police Memorial" />

          <div className="app-mask">
            <h1 className="app-mask__text">
              Unfortunately your screen is too small to display the digital
              memorial
            </h1>
            <p className="app-mask__action">
              Visit the{" "}
              <a href="http://www.ukpolicememorial.org/">
                UK Police Memorial website
              </a>
            </p>
          </div>

          {/****
						Wall of faces
						only show when path matches /remembrance
					****/}
          {this.props.location.pathname.match("/remembrance") && (
            <WallOfFacesContainer />
          )}

          {/****
						/
					****/}
          <CSSTransitionGroup
            classNames="basic-fade"
            appear={true}
            timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
          >
            <Switch
              location={this.props.location}
              key={this.props.location.key}
            >
              <Route
                exact
                path="/"
                location={this.props.location}
                key={this.props.location.key}
                render={({ location }) => (
                  <div>
                    <DashboardContainer />
                    {
                      // If location has state then show the 404 page.
                      // This is not optimal - we are not explicitly looking for a 404 notifier
                      // This could become problematic if we start passing more values to location.state
                      this.props.location.state && <PageNotFound />
                    }
                  </div>
                )}
              />

              <Route
                exact
                path="/remembrance"
                location={this.props.location}
                key={this.props.location.key}
                render={({ location }) => (
                  <div>
                    <Logo />
                    <DashboardButton />

                    <CSSTransitionGroup
                      classNames="remembrance-start"
                      appear={true}
                      timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                    >
                      <RemembranceStart key={this.props.location.key} />
                    </CSSTransitionGroup>
                  </div>
                )}
              />

              <Route
                exact
                path="/remembrance/search"
                location={this.props.location}
                key={this.props.location.key}
                render={({ location }) => (
                  <div>
                    <HomeButton />
                    <BackButton />
                    <Logo />
                    <DashboardButton />

                    <CSSTransitionGroup
                      classNames="remembrance-search"
                      appear={true}
                      timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                    >
                      <RemembranceSearch key={this.props.location.key} />
                    </CSSTransitionGroup>
                  </div>
                )}
              />

              <Route
                exact
                path="/remembrance/search/:name"
                location={this.props.location}
                key={this.props.location.key}
                render={(props) => (
                  <div>
                    <HomeButton />
                    <BackButton />
                    <Logo />
                    <DashboardButton />

                    <CSSTransitionGroup
                      classNames="basic-fade"
                      appear={true}
                      timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                    >
                      <RemembranceSearchResultsContainer
                        {...props}
                        key={this.props.location.key}
                      />
                    </CSSTransitionGroup>
                  </div>
                )}
              />

              <Route
                exact
                path="/remembrance/card/:id"
                location={this.props.location}
                key={this.props.location.key}
                render={
                  // ensure props are passed from the parent component correctly. See: https://stackoverflow.com/a/46197259/874691
                  (props) => (
                    <div>
                      <HomeButton />
                      <BackButton />
                      <Logo />
                      <DashboardButton />

                      <CSSTransitionGroup
                        classNames="officer-profile-card"
                        appear={true}
                        timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                      >
                        <OfficerProfileCardContainer
                          {...props}
                          key={this.props.location.key}
                        />
                      </CSSTransitionGroup>
                    </div>
                  )
                }
              />

              <Route
                path="/remembrance/profile/:id"
                component={OfficerProfileFullContainer}
              />

              <Route
                exact
                path="/remembrance/explore"
                location={this.props.location}
                key={this.props.location.key}
                render={({ location }) => (
                  <div>
                    <HomeButton />
                    <BackButton />
                    <Logo />
                    <DashboardButton />

                    <CSSTransitionGroup
                      classNames="explore-start"
                      appear={true}
                      timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                    >
                      <RemembranceExplore key={this.props.location.key} />
                    </CSSTransitionGroup>
                  </div>
                )}
              />

              <Route
                exact
                path="/remembrance/explore/otd"
                location={this.props.location}
                key={this.props.location.key}
                render={({ location }) => (
                  <div>
                    <HomeButton />
                    <BackButton />
                    <Logo />
                    <DashboardButton />

                    <CSSTransitionGroup
                      classNames="explore-box"
                      appear={true}
                      timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                    >
                      <RemembranceExploreOtdContainer
                        key={this.props.location.key}
                      />
                    </CSSTransitionGroup>
                  </div>
                )}
              />

              <Route
                exact
                path="/remembrance/explore/force"
                location={this.props.location}
                key={this.props.location.key}
                render={
                  // ensure props are passed from the parent component correctly. See: https://stackoverflow.com/a/46197259/874691
                  (props) => (
                    <div>
                      <HomeButton />
                      <BackButton />
                      <Logo />
                      <DashboardButton />

                      <CSSTransitionGroup
                        classNames="explore-box"
                        appear={true}
                        timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                      >
                        <RemembranceExploreByForceContainer
                          {...props}
                          key={this.props.location.key}
                        />
                      </CSSTransitionGroup>
                    </div>
                  )
                }
              />

              <Route
                path="/remembrance/explore/force/:id"
                location={this.props.location}
                key={this.props.location.key}
                render={
                  // ensure props are passed from the parent component correctly. See: https://stackoverflow.com/a/46197259/874691

                  (props) => (
                    <div>
                      <HomeButton />
                      <BackButton />
                      <Logo />
                      <DashboardButton />

                      <CSSTransitionGroup
                        classNames="explore-box"
                        appear={true}
                        timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                      >
                        <RemembranceExploreByForceIdContainer
                          {...props}
                          key={this.props.location.key}
                        />
                      </CSSTransitionGroup>
                    </div>
                  )
                }
              />

              <Route
                exact
                path="/remembrance/explore/rank"
                location={this.props.location}
                key={this.props.location.key}
                render={
                  // ensure props are passed from the parent component correctly. See: https://stackoverflow.com/a/46197259/874691
                  (props) => (
                    <div>
                      <HomeButton />
                      <BackButton />
                      <Logo />
                      <DashboardButton />

                      <CSSTransitionGroup
                        classNames="explore-box"
                        appear={true}
                        timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                      >
                        <RemembranceExploreByRankContainer
                          {...props}
                          key={this.props.location.key}
                        />
                      </CSSTransitionGroup>
                    </div>
                  )
                }
              />

              <Route
                path="/remembrance/explore/rank/:id"
                location={this.props.location}
                key={this.props.location.key}
                render={
                  // ensure props are passed from the parent component correctly. See: https://stackoverflow.com/a/46197259/874691

                  (props) => (
                    <div>
                      <HomeButton />
                      <BackButton />
                      <Logo />
                      <DashboardButton />

                      <CSSTransitionGroup
                        classNames="explore-box"
                        appear={true}
                        timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                      >
                        <RemembranceExploreByRankIdContainer
                          {...props}
                          key={this.props.location.key}
                        />
                      </CSSTransitionGroup>
                    </div>
                  )
                }
              />

              <Route exact path="/history" component={HistoryStartContainer} />

              <Route path="/history/:id" component={HistoryEventContainer} />

              {/* If no paths are matched, redirect to the dashboard */}
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: "404" },
                }}
              />
            </Switch>
          </CSSTransitionGroup>
        </div>
      );
    }
  }
}

export default App;
