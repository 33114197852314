import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import DocumentTitle from "react-document-title";
import { Redirect } from "react-router-dom";

function CSSTransitionGroup(props) {
  return (
    <TransitionGroup>
      <CSSTransition {...props} />
    </TransitionGroup>
  );
}

class RemembranceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      showSearchNotification: false,
      showAudioSearch: true,
      prompt: "Type the officer's name",
    };
  }

  // Handle the search input on key up
  // Get the value, match the length (must be longer than 3 chars)
  _handleSearch = (e) => {
    let searchTerm = e.target.value;
    if (searchTerm.length > 2) {
      this.setState({
        showSearchNotification: true,
        showAudioSearch: false,
      });

      // If form is submitted (i.e. return key pressed)
      if (e.key === "Enter") {
        this.setState({
          searchTerm: searchTerm,
        });
      }
    } else {
      this.setState({
        showSearchNotification: false,
        showAudioSearch: true,
      });
    }
  };

  render() {
    if (this.state.searchTerm) {
      return (
        <Redirect
          to={"/remembrance/search/" + this.state.searchTerm}
          push={true}
        />
      );
    } else {
      return (
        <div className={!this.props.fromDashboard && "remembrance-search"}>
          {!this.props.fromDashboard && (
            <DocumentTitle title="Search for a fallen officer" />
          )}

          {!this.props.fromDashboard && (
            <h2 className="remembrance-search__title">
              Search for a fallen officer
            </h2>
          )}
          <input
            onKeyUp={this._handleSearch}
            className="remembrance-search__input"
            placeholder={this.state.prompt}
            aria-label="Search for a fallen officer by name"
          />

          <CSSTransitionGroup
            classNames="remembrance-search__go"
            timeout={{ enter: 500, exit: 500 }}
          >
            {this.state.showSearchNotification ? <SearchText /> : <span />}
          </CSSTransitionGroup>
        </div>
      );
    }
  }
}

const SearchText = () => {
  return <div className="remembrance-search__go">Press enter to search</div>;
};

export default RemembranceSearch;
