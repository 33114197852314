import React, { Component } from 'react';
import RemembranceExploreByRank from '../components/RemembranceExploreByRank';

class RemembranceExploreByRankContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			ranks: [],
			loading: true,
			filterTerm: "", // default value to prevent error being thrown
		}
	}

	_handleFilter = (e) => {
		this.setState({
			filterTerm: e.target.value
		})
	}

	componentDidMount() {
		const self = this;

		window.client.post('ranks/list').then(function(result){

			self.setState({
				loading: false,
	 			ranks: result.ranks
	 		})

		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get this list of police ranks. Please try again later.")
		});
	}

	render() {
		return (
			<RemembranceExploreByRank isLoading={this.state.loading} ranks={this.state.ranks} filter={this._handleFilter} filterTerm={this.state.filterTerm} />
		)
	}
}

export default RemembranceExploreByRankContainer;