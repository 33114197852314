import React, { Component } from 'react';
import RemembranceExploreByRankId from '../components/RemembranceExploreByRankId';

class RemembranceExploreByRankIdContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			officers: [],
			filterTerm: "", // default value to prevent error being thrown
			rank: ""
		}
	}

	_handleFilter = (e) => {
		this.setState({
			filterTerm: e.target.value
		})
	}

	componentDidMount() {
		const self = this;

		window.client.post(
			'officers/rank', 
			{
				"rank_id": this.props.match.params.id, // get the ID from the url
			}
		).then(function(result){
			// console.log(result)
			self.setState({
				loading: false,
				rank: result.rankName,
	 			officers: result.officers
	 		})
		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get information for this police rank. Please try again later.")
		});
	}

	// render the component and pass to it the list of officers and the rank name
	// we're also passing a filter prop which will be initialised by the search field and passed back to this container for filtering
	render() {
		return (
			<RemembranceExploreByRankId 
			isLoading={this.state.loading} 
			officers={this.state.officers} 
			rank={this.state.rank} 
			filter={this._handleFilter} 
			filterTerm={this.state.filterTerm} />
		)
	}
}

export default RemembranceExploreByRankIdContainer