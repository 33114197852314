import React, { Component } from 'react';
import RemembranceExploreByForce from '../components/RemembranceExploreByForce';

class RemembranceExploreByForceContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			forces: [],
			loading: true,
			filterTerm: "", // default value to prevent error being thrown
		}
	}

	_handleFilter = (e) => {
		this.setState({
			filterTerm: e.target.value
		})
	}

	componentDidMount() {
		const self = this;
		
		window.client.post('forces/list').then(function(result){

			self.setState({
				loading: false,
	 			forces: result.forces
	 		})

		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get the list of police forces. Please try again later.")
		});
	}

	render() {
		return (
			<RemembranceExploreByForce 
			isLoading={this.state.loading} 
			forces={this.state.forces} 
			filter={this._handleFilter} 
			filterTerm={this.state.filterTerm} />
		)
	}
}

export default RemembranceExploreByForceContainer;