import React, { Component } from 'react';
import HistoryStart from '../components/HistoryStart';

class HistoryStartContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			events: [],
		}
	}

	componentDidMount() {
		const self = this;

		var d = new Date();
		var monthNum = d.getMonth();

		window.client.post(
			'events', 
			{
				"month": monthNum+1
			}
		).then(function(result){
			self.setState({
	 			events: result.events
	 		})
		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get the events. Please try again later.")
		});
	}

	render() {
		return <HistoryStart events={this.state.events} />
	}
}

export default HistoryStartContainer