import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import OfficerProfileCard from "../components/OfficerProfileCard";
import SocialIcons from "../components/SocialIcons";

function CSSTransitionGroup(props) {
  return (
    <TransitionGroup>
      <CSSTransition {...props} />
    </TransitionGroup>
  );
}

// Set the state, once mounted get the parameters passed from the URL and send to the GetOfficers() function
// Set the state to contain the returned result and then pass this as props to the OfficerProfileCard component
class OfficerProfileCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      officer: {
        name: null,
        dateOfDeath: {
          day: null,
          month: null,
          year: null,
        },
        rank: "",
        force: "",
      },
      error: false,
    };
  }

  componentDidMount() {
    const self = this;

    window.client
      .post("officer/get", {
        officer_id: this.props.match.params.id, // get the ID from the url
      })
      .then(function (result) {
        self.setState({
          officer: result.officer,
        });
      })
      .catch(function (err) {
        self.setState({
          error: err,
        });
      });
  }

  render() {
    return (
      <span>
        <OfficerProfileCard
          isError={this.state.error}
          officer={this.state.officer}
          rank={this.state.rank}
          force={this.state.force}
        />

        {!this.state.error && (
          <CSSTransitionGroup
            classNames="share-icons"
            appear={true}
            enter={false}
            exit={false}
            timeout={{ appear: 2500 }}
          >
            <SocialIcons officer={this.state.officer} />
          </CSSTransitionGroup>
        )}
      </span>
    );
  }
}

export default OfficerProfileCardContainer;
