import React, { Component } from 'react';
import Slide from './Slide';
import Logo from '../components/Logo';
import DashboardButton from '../components/DashboardButton';
import DocumentTitle from 'react-document-title';

class HistoryStart extends Component {
	constructor(props){
		super(props);
		this.state = {
			slides: {
				"id":null,
				"name":null,
				"summary":null,
				"location":null,
				"image":null
			},
			activeSlide: 0,
			slidePosition: 0,
			userNextButton: false,
			slideWidth: 215 // pixel width, inc margin
		}
	}

	// Logic to detect browser width vs number of slides to figure out if next button is needed.
	// Each slide is 215 in width so we will need to get window width and divide by this.
	// If there are more slides than this number, we show the next button.
	componentWillReceiveProps(nextProps){
		let windowWidth = window.innerWidth/this.state.slideWidth;
		let numSlides = nextProps.events.length;
		if (numSlides > windowWidth) {
			this.setState({
				userNextButton: true
			})
		}

		// Get the slides from the props and update the state
		this.setState({
			slides: nextProps.events
		})
	}

	_handleSlideSelect(item) {
		this.setState({
			activeSlide: item, // change the active slide to the current item selected
			// slidePosition: item * ("-" + this.state.slideWidth) // unsure if we want this: get the item and multiple it by -200. this means a slide will move to the far-left automatically on click
		})
	}

	_handleSlidePrev() {
		this.setState({
			slidePosition: this.state.slidePosition + this.state.slideWidth,
		})

		// Work out the point at which the next button disappears
		// Get the number of slides and multiple by the width
		let stoppingPoint = (this.state.slides.length - 2) * this.state.slideWidth;

		// Then, if the position equals/does not equal the stopping point show/don't show the button
		if(this.state.slidePosition !== parseInt("-" + stoppingPoint, 10)){
			this.setState({ userNextButton: true })
		}
	}

	_handleSlideNext() {
		this.setState({
			slidePosition: this.state.slidePosition - this.state.slideWidth,
		})

		let stoppingPoint = (this.state.slides.length - 2) * this.state.slideWidth;

		if(this.state.slidePosition === parseInt("-" + stoppingPoint, 10)){
			this.setState({ userNextButton: false })
		}
	}

	render() {
		let self = this;
		const slides = this.props.events.map(function(slide, item){
			return (
				<li aria-label="Read more about this event" className={"history-slides__slide-thumbnail " + (item === self.state.activeSlide ? "active" : "")} key={item} onClick={self._handleSlideSelect.bind(self, item)}>
					<div className="history-slides__slide-thumbnail__image" style={{backgroundImage:'url(' + slide.image + ')'}}></div>
					<div className="history-slides__slide-thumbnail__details">
						<p className="history-slides__slide-thumbnail__year">{slide.year}</p>
						<h2 className="history-slides__slide-thumbnail__title">{slide.name}</h2>
					</div>
				</li>
			)
		})

		return(
			<div className="history-slides">
				<Logo />
				<DashboardButton />
				<DocumentTitle title="Important events in police history" />
				
				<div className="history-slides__slide-content">
					<Slide slide={this.state.slides[this.state.activeSlide]} />
				</div>

				<div className="history-slides__slides">
					<ul className="slides">
						<div className="slides__mover" style={{left: this.state.slidePosition}}>
							{slides}
						</div>
					</ul>
					{ 
						this.state.slidePosition !== 0 && 
						<button aria-label="Move slides back" onClick={this._handleSlidePrev.bind(this)} className="slides__control slides__prev--history"><img src="/images/ui/icon-slide-prev.svg" alt="Previous slide" /></button>
					}
					{ 
						this.state.userNextButton &&
						<button aria-label="Move slides forward" onClick={this._handleSlideNext.bind(this)} className="slides__control slides__next--history"><img src="/images/ui/icon-slide-next.svg" alt="Next slide" onClick={this._handleSlideNext.bind(this)} /></button>
					}
				</div>
			</div>
		)
	}
}

export default HistoryStart;
