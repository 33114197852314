import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

// Render out the search results page
class RemembranceSearchResults extends Component {
	constructor(props){
		super(props);
		this.state = {
			activeSlide: 0,
			slidePosition: 0,
			userNextButton: false,
			slideWidth: 260 // pixel width inc margin
		}
	}
	
	// Get the props using nextProps and then get the length of the results array
	// If the number of slides works our wider than the container width, we add the next button
	componentWillReceiveProps(nextProps){
		let containerWidth = document.getElementById("resultsContainer").offsetWidth/this.state.slideWidth;
		let numSlides = nextProps.results.length;
		if (numSlides > containerWidth) {
			this.setState({
				userNextButton: true
			})
		}
	}

	_handleSlidePrev() {
		this.setState({
			slidePosition: this.state.slidePosition + this.state.slideWidth,
		})

		let stoppingPoint = (this.props.results.length - 2) * this.state.slideWidth;

		if(this.state.slidePosition !== parseInt("-" + stoppingPoint, 10)){
			this.setState({ userNextButton: true })
		}
	}

	_handleSlideNext() {
		this.setState({
			slidePosition: this.state.slidePosition - this.state.slideWidth,
		})

		let stoppingPoint = (this.props.results.length - 2) * this.state.slideWidth;

		if(this.state.slidePosition === parseInt("-" + stoppingPoint, 10)){
			this.setState({ userNextButton: false })
		}
	}

	render() {

		/**
			We are using JS map() function to create a new array based on the results sent from RemembranceSearchResultsContainer.
			We are then calling them in the final return function below using {officers}
		*/
		const officers = this.props.results.map(function(officer, index){
			return (
				<li 
					key={index} 
					className="officer-profile-head"
					style={{"--head-animation-delay": `${0.1 * index}s`}}
				>
					<Link to={"/remembrance/card/" + officer.id} title="View this officer's profile">
					{
						officer.profilePicture != null ?
						<div>
							<div style={{backgroundImage:'url(' + officer.profilePicture + ')'}} className="officer-profile-head__picture"></div>
							<img src={officer.profilePicture} alt={officer.name} style={{display:"none"}} />
						</div>
						:
						<div style={{backgroundImage:'url(/images/ui/no-officer-picture.svg)'}} className="officer-profile-head__picture"></div>
					}
						<div className="officer-profile-head__details">
							<h2 className="officer-profile-head__name">{officer.name}</h2>
							<div className="officer-profile-head__dates">Died {officer.dateOfDeath.day}/{officer.dateOfDeath.month}/{officer.dateOfDeath.year}</div>
						</div>
					</Link>
				</li>
			)
		})

		if(this.props.isLoading){
			
			return <div className="officer-search-results" id="resultsContainer"><div className="loading--page"></div></div>

		} else if(this.props.isError){

			if(this.props.isError.errorNumber === '5'){
				return (
					<div className="officer-search-results" id="resultsContainer">
						<DocumentTitle title={"Too many results for " + (this.props.name)} />

						<div className="officer-search-results__no-results">
							<h2 className="officer-search-results__title">Too many results for <strong>{this.props.name}</strong></h2>
							<p>There are too many results for <strong>{this.props.name}</strong>, please try again with a more specific search, or contact us if you think this is an error.</p>
							<ul>
								<li className="officer-search-results__button">
									<Link to="/remembrance/search" className="button button--empty-white" title="Search again">Search again</Link>
								</li>
								<li className="officer-search-results__button">
									<Link to="http://www.ukpolicememorial.org/contact" target="_blank" className="button button--empty-white" title="Contact us to report a problem">Contact us</Link>
								</li>
							</ul>
						</div>
					</div>
				)
			}
			else if(this.props.isError.errorNumber === '15'){
				return (
					<div className="officer-search-results" id="resultsContainer">
						<DocumentTitle title={"No results for " + (this.props.name)} />
						<div className="officer-search-results__no-results">
							<h2 className="officer-search-results__title">No results for <strong>{this.props.name}</strong></h2>
							<p>Unfortunately that officer cannot be found in our database. If you think is an error please contact us, or try searching again.</p>
							<ul>
								<li className="officer-search-results__button">
									<Link to="/remembrance/search" className="button button--empty-white" title="Search again">Search again</Link>
								</li>
								<li className="officer-search-results__button">
									<Link to="http://www.ukpolicememorial.org/contact" target="_blank" className="button button--empty-white" title="Contact us to report a problem">Contact us</Link>
								</li>
							</ul>
						</div>
					</div>
				)
			} else {
				return <div className="officer-search-results" id="resultsContainer"><div className="loading__error">Something has gone wrong. Please try reloading the page.</div></div>
			}

		} else {
			return (
				<div className="officer-search-results" id="resultsContainer">
					<DocumentTitle title={"Search results for " + (this.props.name)} />

					<h2 className="officer-search-results__title">
						Search results for <strong>{this.props.name}</strong>
						<Link className="officer-search-results__clear" to="/remembrance/search">
							<img src="/images/ui/close-button.svg" alt="Close and return to search" />
						</Link>
					</h2>

					<ul className="slides">
						<div className="slides__mover" style={{left: this.state.slidePosition}}>
							{officers}
						</div>
					</ul>
					{ 
						this.state.slidePosition !== 0 && 
						<button aria-label="Move slides back" onClick={this._handleSlidePrev.bind(this)} className="slides__control slides__prev--officer"><img src="/images/ui/icon-slide-prev.svg" alt="Previous slide" /></button>
					}
					{ 
						this.state.userNextButton &&
						<button aria-label="Move slides forward" onClick={this._handleSlideNext.bind(this)} className="slides__control slides__next--officer"><img src="/images/ui/icon-slide-next.svg" alt="Next slide" /></button>
					}
				</div>
			)
		}
	}
}

export default RemembranceSearchResults
