import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class FeaturedMedia extends Component {
    state = {
        watchVideo: false
    }

    playVideo(){
        this.setState({
            watchVideo: !this.state.watchVideo
        })
    }

    render(){
        
        let { title, summary, video, profiles, image } = this.props.randomProfile;

        // Get the Vimeo URL
        var url = video;
        // Create a regex on the Vimeo url 
        var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
        // Check if it is a Vimeo URL
        var match = url.match(regExp);
        // Set a blank var for the video player
        let player = null
        if(match[2]){
            player = (
                <iframe title="Vimeo video" src={"https://player.vimeo.com/video/" + match[2] } 
                width="100%" height="506" className="video__player" frameborder="0" style={{border:0}} allow="autoplay; fullscreen" allowfullscreen></iframe>
            )
        }

        let videoPlayer = null
        
        videoPlayer = (
            <div className="popout-video-wrapper" onClick={() => this.playVideo()}>
                <div className="video popout-video">
                    {player}
                    <button onClick={() => this.playVideo()} 
                    className="popout-video__close" aria-label="Close video">
                        <img src="/images/ui/close-button.svg" alt="Close and return" />
                    </button>
                </div>
            </div>
        )

        return (
            <article className="dashboard__media dashboard-box">
                { this.state.watchVideo && videoPlayer}
                <div className="dashboard-box__content">
                    <img src={image} alt="" className="dashboard-box__background-image" />
                    <div className="dashboard__media-content">
                        <div>
                            <h2 className="dashboard-box__title">{title}</h2>
                            <p className="dashboard-box__body">{summary}</p>
                        </div>
                        <ul className="dashboard__media-links">
                            <li><button onClick={() => this.playVideo()} className="button button--empty-white">Watch video</button></li>
                            {
                                (profiles.length) > 0 &&
                                <div>
                                    {
                                    (profiles.length) > 1 ?
                                    <div>
                                        <li><Link to={"/remembrance/profile/" + profiles[0].id} className="button button--empty-white">{profiles[0].button}</Link></li>
                                        <li><Link to={"/remembrance/profile/" + profiles[1].id} className="button button--empty-white">{profiles[1].button}</Link></li>
                                    </div>
                                    :
                                    <li><Link to={"/remembrance/profile/" + profiles[0].id} className="button button--empty-white">{profiles[0].button}</Link></li>
                                    }
                                </div>
                            }
                        </ul>
                    </div>
                </div>
            </article>
        )
    }
}

export default FeaturedMedia