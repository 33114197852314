import React from 'react';
import { Link } from 'react-router-dom';

const Slide = (props) => {
	if(props.slide === undefined) {
		return null;
	} 
	return (
		<div>
			<div className="history-slides__image" style={{backgroundImage:'url(' + props.slide.image + ')'}}></div>

			<div className="history-slides__body">
				<h1 className="history-slides__title">{props.slide.name}</h1>
				<p className="history-slides__description">{props.slide.summary}</p>
				{props.slide.hasStory && <Link to={"/history/" + props.slide.id} className="button button--solid-white history-slides__button" title="Read more about this event" style={{marginTop:20}}>Read more</Link>}
			</div>
		</div>
	)
}

export default Slide;
