import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DashboardOtd extends Component {
	render() {
		let currentYear = new Date().getFullYear()

		const officers = this.props.officers.map(function(officer, index){
			return (
				<li key={index}>
					<Link className="result-item" to={"/remembrance/card/" + officer.id} title="Goto this officer's profile">
						{
							officer.profilePicture != null ? <img src={officer.profilePicture} className="result-item__image" alt="" /> : <img src='/images/ui/no-officer-picture.svg' className="result-item__image" alt="" />
						}
						{officer.name} <span className="result-item__aside">died {currentYear - officer.dateOfDeath.year} years ago today</span>
					</Link>
				</li>
			)
		})
		return (
			<ul>
				{officers}			
			</ul>
		)
	}
}

export default DashboardOtd