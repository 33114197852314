import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class RandomStory extends Component {
    state = {
        event: {}
    }
    componentDidMount(){
        const self = this;

		window.client.post(
			'event/random', {},
		).then(function(result){
			self.setState({
	 			event: result.event
	 		})
		}).catch(function(err){
            console.log(err)
			alert("Unfortunately there has been an error when trying to get information for this event. Please try again later.")
		});
    }

    render() {
        const { image, name, summary, } = this.state.event
        return (
            <div className="dashboard__history dashboard-box">
                <div className="dashboard-box__content">
                    <img src={image} alt="" className="dashboard-box__background-image" />
                    <div className="dashboard__history-content">
                        <h2 className="dashboard-box__title">{name}</h2>
                        <p className="dashboard-box__body">{summary}</p>
                    </div>
                </div>
                <div className="dashboard-box__actions">
                    <ul>
                        <li className="dashboard-box__button">
                            <Link className="button button--solid-dark-blue" to="/history" title="Visit our history section">Learn more about police history</Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default RandomStory