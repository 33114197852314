import React from 'react';

const SocialIcons = (props) => {
	return (
		<div className="share-icons">
			<h3 className="share-this-page share-this-page--white">Share page</h3>
			<ul>
				<li className="share-icons__item">
					<a href={"https://twitter.com/intent/tweet?text=Remembering " + props.officer.name + " who died on " 
					+ props.officer.dateOfDeath.day + "/" + props.officer.dateOfDeath.month + "/" 
					+ props.officer.dateOfDeath.year + ". Read their story here: https://digital.ukpolicememorial.org/remembrance/card/" + props.officer.id} 
					className="share-icons__link" title="Share on Twitter" target="_blank">
						<img src="../../images/ui/button-twitter-white.svg" className="share-icons__icon" alt="Share on Twitter" />
					</a>
				</li>
				<li className="share-icons__item">
					<a href={"https://www.facebook.com/sharer/sharer.php?u=https://digital.ukpolicememorial.org/remembrance/card/" + props.officer.id} 
					className="share-icons__link" title="Share on Facebook" target="_blank">
						<img src="../../images/ui/button-facebook-white.svg" className="share-icons__icon" alt="Share on Facebook" />
					</a>
				</li>
				<li className="share-icons__item">
					<a href={"mailto:?subject=Remembering " + props.officer.name + "&body=Visit the online memorial for " + props.officer.name + " at https://digital.ukpolicememorial.org/remembrance/card/" + props.officer.id} 
					className="share-icons__link" title="Share by email">
						<img src="../../images/ui/button-email-white.svg" className="share-icons__icon" alt="Share by email" />
					</a>
				</li>
			</ul>
		</div>
	)
}
export default SocialIcons