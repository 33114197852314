import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

class RemembranceExploreByForce extends Component {
	render() {
		const forces = this.props.forces
		.filter(force => {
			return force.name.toLowerCase().indexOf(this.props.filterTerm.toLowerCase()) >= 0 
		})
		.map(function(force, index){
			return (
				<li key={index}>
					<Link className="result-item" to={"/remembrance/explore/force/" + force.id} title="View officers in this force">{force.name}</Link>
				</li>
			)
		})

		return (
			<div className="explore-box">
				<DocumentTitle title="Fallen officers by police force" />

				<div className="explore-box__header">
					<h2 className="explore-box__title">Fallen officers by police force</h2>
				</div>
				<div className="explore-box__search">
					<input type="text" className="explore-box__input" 
					autoFocus placeholder="Search for a force" value={this.props.filterTerm} onChange={this.props.filter} />
				</div>
				<ul className="explore-box__results">
					{forces}

					{
						this.props.isLoading &&
						<div className="loading"></div>
					}

					{
						forces.length === 0 &&
						<div className="no-results">
							<h2 className="no-results__title">No results</h2>
							<p className="no-results__body">There are no police forces in our database that match your search.</p>
						</div>
					}
				</ul>
			</div>
		)
	}
}

export default RemembranceExploreByForce