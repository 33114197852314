import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

class RemembranceExploreOtd extends Component {
	render() {
		let currentYear = new Date().getFullYear()

		const officers = this.props.officers.map(function(officer, index){
			return (
				<li key={index}>
					<Link className="result-item" to={"/remembrance/card/" + officer.id} title="Goto this officer's profile">
						{
							officer.profilePicture != null ? <img src={officer.profilePicture} className="result-item__image" alt={officer.name} /> : <img src='/images/ui/no-officer-picture.svg' className="result-item__image" alt={officer.name} />
						}
						{officer.name} <span className="result-item__aside">died {currentYear - officer.dateOfDeath.year} years ago today</span>
					</Link>
				</li>
			)
		})
		return (
			<div className="explore-box">
				<DocumentTitle title="Fallen officers on this day" />

				<div className="explore-box__header">
					<h2 className="explore-box__title">Fallen officers on this day in history</h2>
				</div>
				<ul className="explore-box__results">
					{officers}

					{
						officers.length === 0 &&
						<div className="no-results">
							<h2 className="no-results__title">No results</h2>
							<p className="no-results__body">There are no fallen officers in our database on this day.</p>
						</div>
					}
				</ul>
			</div>
		)
	}
}

export default RemembranceExploreOtd