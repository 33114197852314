import React, { Component } from 'react';
import OfficerProfileFull from '../components/OfficerProfileFull';

// Set the state, once mounted get the parameters passed from the URL and send to the GetOfficers() function
class OfficerProfileFullContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			officer: { 
				name: null, 
				dateOfDeath: { 
					day: null,
					month: null, 
					year: null
				},
				rank: "", 
				force: ""	
			},
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		
		const self = this;

		window.client.post(
			'officer/get', 
			{
				"officer_id": this.props.match.params.id, // get the ID from the url
			}
		).then(function(result){
			// console.log(result.officer)
			self.setState({
	 			officer: result.officer
	 		})
		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get information for this officer. Please try again later.")
		});
    }

	render() {
		return(
			<OfficerProfileFull officer={this.state.officer} rank={this.state.rank} force={this.state.force} />
		)
	}
}

export default OfficerProfileFullContainer;