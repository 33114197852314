import React, { Component } from 'react';
import WallOfFaces from '../components/WallOfFaces';

class WallOfFacesContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			faces: []
		}
	}

	componentDidMount() {
		const self = this;

		window.client.post('wall/get').then(function(result){
			var faceList = result.pictures
			faceList = faceList.concat(faceList.slice(0)).concat(faceList.slice(0))
			self.setState({
				faces: faceList
	 		})

		}).catch(function(err){
			console.log(err);
		});
	}

	render() {
		return <WallOfFaces faces={this.state.faces} />
	}
}

export default WallOfFacesContainer;