import React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

const OfficerProfileCard = (props) => {
	if(props.isError){
		return (
			<div className="officer-profile-card">
				<div className="officer-search-results__no-results">
					<p>
						Unfortunately that officer cannot be found in our database. If you think is an error please contact us, or try searching again.
					</p>
					<ul>
						<li className="officer-search-results__button">
							<Link to="/remembrance/search" className="button button--empty-white" title="Search again">Search again</Link>
						</li>
						<li className="officer-search-results__button">
							<Link to="http://www.ukpolicememorial.org/contact" target="_blank" className="button button--empty-white" title="Contact us to report a problem">Contact us</Link>
						</li>
					</ul>
				</div>
			</div>
		)
	} else {
		return (
			<div className="officer-profile-card">
				<div className="officer-profile-card__main">
					<DocumentTitle title={"Remembering " + props.officer.name} />

					{
						!props.officer.disabledContribute &&
						<a rel="noopener noreferrer" href="https://www.ukpolicememorial.com/officer-more-information/" 
						target="_blank" 
						className="officer-profile-card__contribute" 
						title="Tell us if you have more information about this officer">Contribute Information</a>
					}

					{
						props.officer.profilePicture != null ? <img src={props.officer.profilePicture} className="officer-profile-card__picture" alt={props.officer.name} /> : <img src='/images/ui/no-officer-picture.svg' className="officer-profile-card__picture" alt={props.officer.name} />
					}
					<h1 className="officer-profile-card__name">{props.officer.name}</h1>
					<div className="officer-profile-card__details">{props.officer.rank.name}, {props.officer.force.name}</div>
					{props.officer.shortBio ? <div className="officer-profile-card__dates">Died {props.officer.dateOfDeath.day}/{props.officer.dateOfDeath.month}/{props.officer.dateOfDeath.year}</div> : <div className="officer-profile-card__dates" style={{marginBottom:"0"}}>Died {props.officer.dateOfDeath.day}/{props.officer.dateOfDeath.month}/{props.officer.dateOfDeath.year}</div> }
					{props.officer.profile ? <p className="officer-profile-card__body">{props.officer.shortBio}</p> : <p className="officer-profile-card__body" style={{marginBottom:"0"}}>{props.officer.shortBio}</p> }
					<ul>
						{
							props.officer.profile &&
							<li className="officer-profile-card__button">
								<Link className="button button--empty-white" to={"/remembrance/profile/" + props.officer.id} title="Read officer's story"><span>Read their</span> story</Link>
							</li>
						}
					</ul>
				</div>
			</div>
		)
	}
}

export default OfficerProfileCard