import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

class RemembranceExploreByRank extends Component {
	render() {
		const ranks = this.props.ranks
		.filter(rank => {
			return rank.name.toLowerCase().indexOf(this.props.filterTerm.toLowerCase()) >= 0 
		})
		.map(function(rank, index){
			return (
				<li key={index}>
					<Link className="result-item" to={"/remembrance/explore/rank/" + rank.id} title="Explore police officers in this rank">{rank.name}</Link>
				</li>
			)
		})

		return (
			<div className="explore-box">
				<DocumentTitle title="Fallen officers by rank" />

				<div className="explore-box__header">
					<h2 className="explore-box__title">Fallen officers by rank</h2>
				</div>
				<div className="explore-box__search">
					<input type="text" className="explore-box__input" 
					autoFocus placeholder="Search for a rank" onChange={this.props.filter} aria-label="Filter ranks by title" />
				</div>
				<ul className="explore-box__results">
					{ranks}

					{
						this.props.isLoading &&
						<div className="loading"></div>
					}

					{
						ranks.length === 0 &&
						<div className="no-results">
							<h2 className="no-results__title">No results</h2>
							<p className="no-results__body">There are no ranks in our database that match your search.</p>
						</div>
					}
				</ul>
			</div>
		)
	}
}

export default RemembranceExploreByRank