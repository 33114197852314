import React, { Component } from 'react';
import HistoryEvent from '../components/HistoryEvent';

class HistoryEventContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			event: [],
		}
	}

	componentDidMount() {
		const self = this;

		window.client.post(
			'event/get', 
			{
				"event_id": self.props.match.params.id
			}
		).then(function(result){
			// console.log(result.event)
			self.setState({
	 			event: result.event
	 		})
		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get information for this event. Please try again later.")
		});
	}

	render() {
		return (
			<HistoryEvent event={this.state.event} />
		)
	}
}

export default HistoryEventContainer