import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './css/index.css';
import App from './App';

ReactDOM.render(
	<Router>
		<Route render={({location}) => (
	  		<App location={location} />
	  	)}/>
	</Router>,
	document.getElementById('root')
);
