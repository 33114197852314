import React, { Component } from 'react';

class BackButton extends Component {

	_goBack = () => {
		window.history.back();
	}

	render() {
		return <button onClick={this._goBack} className="back-button button button--empty-white" aria-label="Go back to the previous page"><span>Back</span></button>
	}
}

export default BackButton;