import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

class RemembranceExploreByForceId extends Component {
	render() {
		const officers = this.props.officers
		.filter(officer => {
			return officer.name.toLowerCase().indexOf(this.props.filterTerm.toLowerCase()) >= 0 
		})
		.map(function(officer, index){
			return (
				<li key={index}>
					<Link className="result-item" to={"/remembrance/card/" + officer.id} title="View this office's profile">
						{
							officer.profilePicture != null ? <img src={officer.profilePicture} className="result-item__image" alt={officer.name} /> : <img src='/images/ui/no-officer-picture.svg' className="result-item__image" alt={officer.name} />
						}
						{officer.name}
					</Link>
				</li>
			)
		})

		return (
			<div className="explore-box">
				<DocumentTitle title={"Officers in " + (this.props.force)} />

				<div className="explore-box__header">
					<h2 className="explore-box__title">Officers in {this.props.force}</h2>
				</div>

				<div className="explore-box__search">
					<input type="text" className="explore-box__input" 
					autoFocus placeholder="Search for an officer" onChange={this.props.filter} />
				</div>
				
				<ul className="explore-box__results">
					{officers}

					{
						officers.length === 0 &&
						<div className="no-results">
							<h2 className="no-results__title">No results</h2>
							<p className="no-results__body">There are no fallen officers in our database in this force.</p>
						</div>
					}
				</ul>
			</div>
		)
	}
}

export default RemembranceExploreByForceId