import React, { Component } from 'react';
import RemembranceSearchResults from '../components/RemembranceSearchResults';

// Get the parameters sent in the URL and pass it to the results component for rendering
class RemembranceSearchResultsContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			error: false,
			results: [{ 
				name: null, 
				dateOfDeath: { 
					day: null,
					month: null, 
					year: null
				} 
			}],
		}
	}

	// Once the component mounts call the GetOfficers function and pass the search term to it.
	// Use these results to set the state and then pass to the presentational component as props
	componentDidMount() {
		const self = this;

		window.client.post(
			'officers/search', 
			{
				"search_term": this.props.match.params.name, // get the ID from the url
			}
		).then(function(result){
			console.log(result)
			self.setState({
				loading: false,
	 			results: result.officers
	 		})
		}).catch(function(err){
			console.log(err);
			self.setState({
				error: err, 
				loading: false
			})
		});
    }

    render() {
    	return <RemembranceSearchResults 
    	isError={this.state.error} 
    	isLoading={this.state.loading} 
    	results={this.state.results} 
    	name={this.props.match.params.name} />
    }
}

export default RemembranceSearchResultsContainer;