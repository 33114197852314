import React, { Component } from 'react';

class Slider extends Component {
	constructor(){
		super();
		this.state = {
			activeSlide: 0,
			slidePosition: 0,
			expandedImage: null
		}
	}

	moveSlide(direction){
		const { slideWidth, slideMargin } = this.props.config;
		const moveBack = this.state.slidePosition - (slideWidth + slideMargin * 2)
		const moveForward = this.state.slidePosition + (slideWidth + slideMargin * 2)

		switch(direction){
			case 0:
				this.setState({ 
					slidePosition: moveForward,
					activeSlide: this.state.activeSlide-1
				})
				break;
			case 1:
				this.setState({ 
					slidePosition: moveBack,
					activeSlide: this.state.activeSlide+1
				})
				break;
			default:
				return;
		}
	}

	openImage(imagePath){
		this.setState({
			expandedImage: imagePath
		})
	}

	closeImage(){
		this.setState({
			expandedImage: null
		})
	}

	render(){
		const { slideWidth, slideMargin } = this.props.config
		const slides = this.props.slides.map((slide, index) => {
			return (
				<li key={index} 
					className="slider__item"
					style={{width: (slideWidth), 
					marginLeft: (slideMargin), 
					marginRight: (slideMargin),
					backgroundImage:"url("+(slide.path)+")"}}
					onClick={() => this.openImage(slide.path)} >

					<img src={slide.path} alt={slide.caption} style={{display:"none"}} />
				</li>
			)
		})
		return (
			<div className="slider">
				<div className="slider__window">

					<img 
					className="slider__enlarge"
					alt="Enlarge" aria-label="Enlarge" 
					src="/images/ui/icon-magnify.svg" />
					
					<ul className="slider__container"
						style={{transform: "translateX(" + this.state.slidePosition + "px)"  }}>

						{slides}
					</ul>
				</div>

				<div className="slider__detail">
					<Slide data={this.props.slides[this.state.activeSlide]} />

					<ul className="slider__controls">
						{
							this.state.activeSlide > 0 &&

							<li>
								<button 
								className="slider__button"
								onClick={() => this.moveSlide(0)} 
								aria-label="Move slides back">
									Previous
								</button>
							</li>
						}
				
						{
							this.state.activeSlide < this.props.slides.length-1 &&
							<li>
								<button 
								className="slider__button"
								onClick={() => this.moveSlide(1)} 
								aria-label="Move slides forward">
									Next
								</button>
							</li>
						}
					</ul>
				</div>

				{ this.state.expandedImage && <ExpandedImage path={this.state.expandedImage} close={this.closeImage.bind(this)} /> }
			</div>
		)
	}
}

const ExpandedImage = (props) => {
	return (
		<div className="slider__lightbox" onClick={props.close}>
			<img src={props.path} alt="" />
			<button onClick={props.close}>CLOSE</button>
		</div>
	)
}

const Slide = (props) => {
	return (
		<div>
			<p className="slider__content">{props.data.caption}</p>
			<p><a href={props.data.source} className="slider__source" target="_blank" title="Open source in a new browser tab">Source</a></p>
		</div>
	)
}

export default Slider