import React, { Component } from 'react';
import RemembranceExploreOtd from '../components/RemembranceExploreOtd';
import DashboardOtd from '../components/DashboardOtd';

class RemembranceExploreOtdContainer extends Component {
	constructor(props){
		super(props);
		this.state = {
			officers: []
		}
	}

	componentDidMount(){
		const self = this;
		const date = new Date();
		const thisDay = date.getDate();
		const thisMonth = ("0" + (date.getMonth() + 1)).slice(-2);
		
		window.client.post(
			'officers/day', 
			{
				"day": thisDay,
				"month": thisMonth
			}
		).then(function(result){
			self.setState({
				loading: false,
	 			officers: result.officers
	 		})
		}).catch(function(err){
			alert("Unfortunately there has been an error when trying to get officers fallen on this day. Please try again later.")
		});
	}

	render() {
		if(this.props.fromDashboard){
			return <DashboardOtd officers={this.state.officers} />
		}
		return (
			<RemembranceExploreOtd officers={this.state.officers} />
		)
	}
}

export default RemembranceExploreOtdContainer;